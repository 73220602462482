import React, {useEffect, useState} from 'react';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import obj from '../assets/models/entrance4.glb';


function Entrancepage() {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {

        //CANVAS
        const canvas = document.querySelector('canvas.webglEntrance');
        const scene = new THREE.Scene();
    
        // scene.background = new THREE.Color(0x1fffff);
    
        let door1, door2;
        const render = new GLTFLoader();
        render.load(obj, function(gltf) {  
            const obj = gltf.scene;
            obj.position.y = 0
            obj.position.x = 0
            obj.position.z = 0
            obj.scale.set(1,1,1)
            scene.add(obj);  

            door1 = gltf.scene.getObjectByName('Door1');
            door2 = gltf.scene.getObjectByName('Door2');
            
            setLoading(false);

        }, function(xhr){
            // console.log((xhr.loaded/xhr.total*1000)+"% loaded")
        }, function(error){
            // console.log(error,'ERROR occured')
        }) 

        const targetPosition = new THREE.Vector3(0, 0, -210);
 
        // LIGHT
        const light = new THREE.DirectionalLight(0xffffff,4)
        light.position.set(0,3,20)
        scene.add(light)
    
    
        // WINDOW SIZE
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        window.addEventListener('resize', () => {
            // Update sizes
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;
    
            // Update camera
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()
    
            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })
    
        // CAMERA
        const camera = new THREE.PerspectiveCamera(75, sizes.width/sizes.height, 0.1,100);
        camera.position.set(0,0,0);
        scene.add(camera)

        // CONTROLS
        const controls = new OrbitControls(camera, canvas)
        controls.enableDamping = true
        controls.enableZoom= true
        controls.enablePan = false; // Disable panning
        controls.enableRotate = false; // Disable rotating
        controls.target.copy(targetPosition);
    
        //RENDERER
        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            alpha: true
        })
        renderer.setSize(sizes.width,sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
        renderer.shadowMap.enabled = true
        renderer.gammaOutput = true
        renderer.setClearColor(0x000000,0);

        //ANIMATION
        function animate(){
            requestAnimationFrame(animate)
            renderer.render(scene,camera)

            const cameraZ = camera.position.z;
            console.log("CAMERA", cameraZ);
            
            if( door1 && door2){

                if( cameraZ > -180){
                    door1.rotation.y = 0;
                    door2.rotation.y = 0;
                }
                if(cameraZ <= -180){
                    door1.rotation.y += cameraZ * 0.00002;
                    door2.rotation.y += cameraZ * -0.00002;
                }
                if(cameraZ < -203){
                    door1.rotation.y = -8.5;
                    door2.rotation.y = 8.5;
                }

                if( cameraZ < -205){
                    // let entrance = documtent.addEventListener

                    window.location.href = "/home";
                }
            }
            controls.update();
        }
        animate() 
    }, [])
  return (
    <div className='entrance'>
        {loading ? (
            <div className='loading' id="loading-screen">
            <p>Loading...</p>
            </div>
        ) : null}
        <canvas class='webglEntrance' id='webglEntrance'></canvas>
    </div>
  )
}

export default Entrancepage