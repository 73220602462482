import React from 'react';

import {Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import video from '../../assets/videos/articles/PostHuman/posthuman-vid_1.mp4';

import img1 from '../../assets/images/articles/PostHuman/posthuman-opbouw_17.JPG';
import img2 from '../../assets/images/articles/PostHuman/posthuman-party_2.jpg';
import img3 from '../../assets/images/articles/PostHuman/bib.png';
import img4 from '../../assets/images/articles/PostHuman/posthuman-party_8.jpg';
import img5 from '../../assets/images/articles/PostHuman/posthuman-opbouw_1.JPG';
import img6 from '../../assets/images/articles/PostHuman/posthuman-opbouw_22.jpg';
import img7 from '../../assets/images/articles/PostHuman/posthuman-opbouw_15.JPG';
import img8 from '../../assets/images/articles/PostHuman/posthuman-opbouw_6.JPG';
import img9 from '../../assets/images/articles/PostHuman/posthuman-opbouw_21.JPG';
import img10 from '../../assets/images/articles/PostHuman/posthuman-opbouw_12.JPG';
import img11 from '../../assets/images/articles/PostHuman/posthuman-party_13.JPG';
import img12 from '../../assets/images/articles/PostHuman/posthuman-party_3.jpg';
import img13 from '../../assets/images/articles/PostHuman/posthuman-party_4.jpg';

import downloadArticle from '../../assets/pdf/burenhinder_post-human.pdf';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid black',
      borderRadius: '1px',
    },
}));

function PostHuman() {
  return (
    <div className='posthuman' id='background'>
        <div className='posthuman__backgroundVideo'>
            <div className='posthuman__backgroundVideo__wrapper'>
                <div className='posthuman__backgroundVideo__title'>
                    <h1 className='editH1'>InSitu Atelier: Post Human</h1>
                    <h2 className='editH2'>Lars Marcoen | 29.05.2023</h2>
                </div>
            </div>
            <video src={video} width="100%" height="auto" loop autoPlay muted playsInline></video>
        </div>

        <div className='posthuman__container'>
            <div className='posthuman__container__title'>
                <h1 className='editH1'>InSitu Atelier: <br /> Post Human</h1>
                <h2 className='editH2'>Lars Marcoen | 29.05.2023</h2>
            </div>

            <div className='posthuman__container__intro'>
                <div className='posthuman__intro__img'>
                    <img src={img1} alt="" />
                    <p className='credit__margin'> @Simon Breynaert </p>
                </div>
                <div className='posthuman__intro__text'>
                    Though both are bound in a spiral dance,  I would <br />rather be a cyborg than a goddess. <br /> - Donna Haraway
                    <br /><br />
                    In the year of 2023, each resident of Burenhinder is organizing an event that fits within their vision, interests and style. The event that took place at Recyclart on 18 March was organized and curated by Lola Ilegems. The theme of the club night was Post Human, think all things cybernetic and post-organic. This theme comes further from her admiration for technology and its potential for the human body. In her design practices, she likes to combine technology and fashion. Club Dérive was contacted to reflect these elements on the scenography and styling. 
                </div>
            </div>

            <div className='posthuman__container__bigImg'>
                <img className='posthuman__big__img' src={img2} alt="" />
                <p className='credit__margin'> @Simon Breynaert </p>
            </div>

            <div className="posthuman__container__body" >
                <div className='posthuman__body__text' >
                    <h2  id='get__H2'>
                        Self Expression
                    </h2>

                    <div>
                        The first question we posed was: ‘What is posthumanism?’ 
                        <br /><br />
                        In our first internal workshop we started struggling with the concept of posthumanism as a theme. For us, clubbing has the ability to reflect our society and provides a platform to experiment within. Slapping on a theme as cyborgism or posthumanism felt very Tomorrowland for robots. 
                        <br /><br />
                        What we took with us was the element of self-expression. In a post-human/cyborg world, everyone has the chance to be who- or whatever they want to be. As Donna Harraway wrote in her Cyborg's manifesto, "The cyborg is a creature in a postgender world." A cyborg is a hybrid figure, neither fully organic nor exclusively mechanical and moves between ontological and social categories, representing an ultimate blurring of classificatory boundaries<HtmlTooltip title="Haraway, D. (1987). A manifesto for cyborgs: Science, technology, and socialist feminism in the 1980s. In Cambridge University Press eBooks (pp. 82–116). https://doi.org/10.1017/cbo9780511570940.007" placement="bottom-start"><sup className='tooltip_ref'>1</sup></HtmlTooltip>. We can imagine ourselves expressing our identity through biomechanical adaptations and additions. Diving deeper into this ‘self-expression’ as behavior we seeked ways to improve this behavior in the club space. 

                        {/* <HtmlTooltip title="Haraway, D. (1987). A manifesto for cyborgs: Science, technology, and socialist feminism in the 1980s. In Cambridge University Press eBooks (pp. 82–116). https://doi.org/10.1017/cbo9780511570940.007" placement="bottom-start"><sup className='tooltip_ref'>1</sup></HtmlTooltip> */}
                        <br /><br />
                        Self-expressing behavior became the fundamental of our research. What actions in the club space are self-expressing? How can we improve, enforce or nurture this behavior by designing the night? How can we encourage individual and collective expression by designing our surroundings? 
                    </div>
                </div>
                <div className='posthuman__body__image'>
                    <img src={img3} alt=""  />
                </div>
            </div>


            <div className="posthuman__container__body">
                <div className='posthuman__body__text__middle' id='get__H2'>
                    <h2  id='get__H2'>Method</h2>
                        <p>
                            While hosting the atelier and writing the article a position of a curious ‘not-knowing’ is handled. It is obvious that experiences are missing from previous discourses regarding club cultures, eyes and ears wide open, the aim is an inclusive perspective.             
                        </p>
                    <br />
                    The talented Burenhinder collective was consulted to join us in a playful ‘bakloving’ workshop.
                </div>
            </div>
            
            {/* 
            <div className="posthuman__container__body" >
                <div className='posthuman__body__text' >
                    <h2  id='get__H2'>Method</h2>
                    <p>
                        While hosting the atelier and writing the article a position of a curious ‘not-knowing’ is handled. It is obvious that experiences are missing from previous discourses regarding club cultures, eyes and ears wide open, the aim is an inclusive perspective.             
                    </p>
                    <br />
                    The talented Burenhinder collective was consulted to join us in a playful ‘bakloving’ workshop.
                </div>
                <div className='posthuman__body__image'>
            </div>
            </div> */}

            <div className="posthuman__container__body posthuman__workshop__container">
            <div className='posthuman__workshop__blur__img'>
                <img src={img4} alt=""  />
            </div>

            <img className='posthuman__workshop__images posthuman__workshop__images__1' src={img5} alt=""  />
            <img className='posthuman__workshop__images posthuman__workshop__images__2' src={img6} alt=""  />
            <img className='posthuman__workshop__images posthuman__workshop__images__5' src={img7} alt=""  />
            <img className='posthuman__workshop__images posthuman__workshop__images__6' src={img8} alt=""  />

                <div className='posthuman__container__workshop'>
                    <div className='posthuman__body__text__workshop' id='get__H2'>
                        <h2 className='posthuman__workshop__h2'>0. Introduction on Club Dérive, Situationism & care in clubspaces </h2>

                        <div className='posthuman__workshop__subtitle'>
                            I. &nbsp; &nbsp; &nbsp;    What makes a club?
                            <div className='posthuman__workshop__subtext'>
                                An exercise we, up until now, always imply in the workshop format is lended from Emma Warren, writer of ‘Make Some Space’ & ‘Document Your Culture’. The answers are a guaranteed reflection on the broad spectrum of perspectives. Participants were let free to draw, write and reflect on pieces of paper that were discussed afterwards.                 
                            </div>

                            II.  &nbsp; &nbsp; &nbsp; Timeline
                            <div className='posthuman__workshop__subtext'>
                                To start, the club night had to be dissected space and time wise. Nothing better than an enormous timeline dissecting the relation between Self Expressing Actions and the Place or Location they were performed. Where and when does a club night really begin or end? We could argue the first second a digital promotion pops up your screen. The first caring action might be you commenting on the instagram post with your favorite emoticons. The last, ordering some healthy take out the day after. We noticed that the discussion revolving around this exercise brought up a lot of anecdotes, some playful and blissful, others deeply personal and delicate. At such a moment we are all responsible to show our understanding and provide comfort.                 
                            </div>

                            III. &nbsp; &nbsp; &nbsp; Discover the space
                            <div className='posthuman__workshop__subtext'>
                                Mapping the Self Expressing Actions on the floor plan gives great insights as spatial designers. Atmospheres are distinguished when taking a virtual stroll through the venue. What has the space to offer? Are there elements that could use some improvement? What elements are already caring? What elements are everything but caring?                  
                            </div>

                            IV.  &nbsp; &nbsp; &nbsp; Design the action, the answer
                            <div className='posthuman__workshop__subtext'>
                                When the Caring Actions are mapped, we can’t stop but wonder how to improve, encourage and support this behavior. Likewise, when observing negative behavior we try to achieve the contrary. We attempt to obtain the new behavior by designing and thus changing the environment around us. Mostly by giving suggestions, breaking down constraints and leaving open more room for experimentation and expression. 
                            </div>

                            <br /> <br />
                            The result, dozens of hilarious, playful and daring interventions to implement at the venue. From encouraging toilets, new ways to express yourself in the que and massage tools to morphing easter eggs. Some turned into reality, others staying on paper. 

                        </div>
                    </div>
                </div>
            </div>
            <p className='credit__margin'> @Simon Breynaert </p>

            <div className="posthuman__container__body">
                <div className='posthuman__body__text__middle' id='get__H2'>
                    <h2>Installations</h2>
                
                    Because the venue of Recyclart is spacious, we invited three installations to bring the scenography to the next level. 
                    <br /> <br />
                    Isabel Brems provided the chill area with beautiful woven cushions. Large enough to rest and cool down from hakking. Each piece is a large snake made out of reused plastic materials stuffed with recycled filling. 
                    <br /> <br />
                    Derege lit up the chill area with his enormous light installations made out of old TL-frames and computer screens. The semi transparent sheet of plastic was placed over a self welded framework that supported the big cases.  
                    <br /> <br />
                    Jazie offered us the ‘rebirth coffin’ photo experience. A 195x45x45 installation made out of plexiglass where Jazie takes pictures and explores his research; Safer Space's evolution in Belgium. Clubbers could pose in the semi self reflective box, receiving beautiful imagery later. 
                </div>
            </div>

            <div className='posthuman__container__imgRow'>
                <img className='posthuman__imgRow__img' src={img9} alt="" />
                <img className='posthuman__imgRow__img' src={img10} alt="" />
                <img className='posthuman__imgRow__img' src={img11} alt="" />
            </div>

            <div className="posthuman__container__body" >
                <div className='posthuman__body__text' >
                    <h2  id='get__H2'>Conceptual</h2>

                    <div>
                        After greeting security, one of the first social encounters at the venue is the waiting line outside. How can we reshape, bend or reform? Inspiring ourselves by ‘The Spiral Jetty’, drawing a spiral on the floor could influence the shape. Social interactions are enforced when surrounded by the queue left and right. 
                        <br /><br />
                        The centerpiece of the night was a grand aluminum structure lended from Recyclart Fabrik, placed almost at the end of the venue. By creating a space within a space different dynamics could be experienced. In the center of the structure, a semi boiler room effect was created with the Dj on ground level and a chandelier floating above their heads. On the frontside the structure was open. The energy experienced close at the Dj was beyond this world. Reflecting on this element, two or even three openings at the side could have been better for the flow. 
                        <br /><br />
                        We delineated a chill room in the main hall. At Recyclart, this hall is the bar area, ticketing location as well as the threshold space between the dancefloor, outside and the sanitary. Because a lot is taking place, a material division was needed. Safety blankets proved to be an excellent choice because of their lightness, mesmerizing reflection, hidden pattern and opacity. When walking past, the whole wall waved and reflected all the light in different directions. Behind the wall the installations of Isabel Brems & Derege were given a unique location. To encourage side activity we installed ‘toolkits’. These semi-gimmicky toolkits allowed users to explore different sensations. A toolkit existed out of: sleeping masks, space blankets, bubble blowers and head massage headgear. 
                        <br /><br />
                        An adventurous game was sought and found by hiding amorph easter eggs in the venue. Clubbers found, weirdly glued to each other, amorph easter eggs that could savor through the night.             
                    </div>
                </div>
                <div className='posthuman__body__image'>
                    <img src={img12} alt=""  />
                </div>
            </div>

            <div className="posthuman__container__body">
                <div className='posthuman__body__text__middle' id='get__H2'>
                    All the various storylines culminated in an unforgettable night. We were stunned by what the post-human world had to offer. The promising night filled with wondrous installations and earth-shaking hardcore tempos became a reality. The dance floor was packed and the different energies in each atmosphere by creating rooms within rooms impressed us the most. By keeping the dancefloor dark, the center light piece delivered. That’s why we want to thank everyone from the Recyclart team, Burenhinder and everybody that worked on this production. Merci                 
                    <br /><br />
                    Thank you! Lola, Ariana, Marieke, Nel, Marie, Tanja, Isabel, Jazie, Derege
                </div>
            </div>

            <div className="posthuman__container__body">
                <div className='posthuman__body__text__middle middle__center' id='get__H2'>
                    <div className='display__flex'>
                        <div className='article__download__button'>
                            <a href={downloadArticle} download="article_post-human_Lars-Marcoen">Download Article</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='posthuman__container__bigImg'>
                <img className='posthuman__big__img' src={img13} alt="" />
                <p className='credit__margin'> @Simon Breynaert </p>
            </div>

            <div className="posthuman__container__body">
                <div className='posthuman__body__text__middle' id='get__H2'>
                    <h2>Sources</h2>
                    Haraway, D. (1987). A manifesto for cyborgs: Science, technology, and socialist feminism in the 1980s. In Cambridge University Press eBooks (pp. 82–116).
                    <br /> https://doi.org/10.1017/cbo9780511570940.007
                    <br /><br />
                    Thweatt-Bates, J. (2012). Cyborg Selves: A Theological Anthropology of the Posthuman. 
                    <br /> https://openlibrary.org/books/OL25162515M/Cyborg_selves
                </div>
            </div>

      </div>

    </div>
  )
}

export default PostHuman