export const transitionData = [
    {
      src: require("../images/articles/transitie/transition1.jpg"),
      publishedAt: "2022-07-14",
      author: "Lars Marcoen",
      slug: "transition-spaces",
      category: "article",
      order: "2023-09-30"
    },
    {
      src: require("../images/articles/transitie/transition2.png"),
      publishedAt: "2022-07-14",
      author: "Lars Marcoen",
      slug: "transition-spaces",
      category: "article",
      order: "2023-09-30"
    },
    {
      src: require("../images/articles/transitie/transition3.jpg"),
      publishedAt: "2022-07-14",
      author: "Lars Marcoen",
      slug: "transition-spaces",
      category: "article",
      order: "2023-09-30"
    },
    {
      src: require("../images/articles/transitie/transition4.png"),
      publishedAt: "2022-07-14",
      author: "Lars Marcoen",
      slug: "transition-spaces",
      category: "article",
      order: "2023-09-30"
    },
    
]
