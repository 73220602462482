export const contributersData = [
    {
      name: "Lars Marcoen",
      link: "https://www.instagram.com/lars.marccoen/",
      projects: [
        { name: "Montage" },
        { name: "LLOSS" },
        { name: "Club Dérive" },
        { name: "Burenhinder" },
        { name: "Kartier Maché" },
        { name: "Genk Onstage" },
        { name: "Club Dérive Website" },
        { name: "De Serre - Wildgroei" },
      ]
    }, 
    {
        name: "Simon Breynaert",
        link: "https://www.instagram.com/smn_brynrt/",
        projects: [
          { name: "Montage" },
          { name: "LLOSS" },
          { name: "Club Dérive" },
          { name: "Burenhinder" },
          { name: "Kartier Maché" },
          { name: "Woodblocks" },
          { name: "Microwave" },
          { name: "De Serre - Wildgroei" },
        ]
    },
    {
        name: "Cosima Rosie Lagae",
        link: "https://www.instagram.com/cosima.rosie/",
        projects: [
          { name: "Montage" },
          { name: "LLOSS" },
          { name: "Club Dérive" },
          { name: "Burenhinder" },
          { name: "Kartier Maché" },
          { name: "Woodblocks" },
          { name: "Club Dérive Website" },
        ]
    },
    {
        name: "Gust Hamerlinck",
        link: "https://www.instagram.com/gguusstt____/",
        projects: [
          { name: "Club Dérive" },
        ]
    }, 
    {
        name: "Lola Ilegems",
        link: "https://www.instagram.com/lolailegems/",
        projects: [
          { name: "Club Dérive" },
          { name: "Burenhinder" },
        ]
    }, 
    {
        name: "Samuel Hoornaert",
        link: "https://www.instagram.com/samuelhoornaert/",
        projects: [
          { name: "Club Dérive" },
          { name: "Woodblocks" },
          { name: "Microwave" },
        ]
    }, 
    {
        name: "Tanja Mala Ngombe",
        link: "https://www.instagram.com/saffierblauw/",
        projects: [
          { name: "Club Dérive" },
          { name: "Burenhinder" },
          { name: "De Serre" },
        ]
    }, 
    {
        name: "Loïc Tindemans",
        link: "https://www.instagram.com/loictindemans/",
        projects: [
          { name: "Club Dérive" },
          { name: "Woodblocks" },
          { name: "Microwave" },
        ]
    }, 
    {
        name: "Manou Van Den Eynde",
        link: "https://stelplaats.be/blog/interview-clubdiner-met-manou-van-den-eynde",
        projects: [
          { name: "Club Dérive" },
        ]
    }, 
    {
        name: "Cedric Vanhool",
        link: "https://www.instagram.com/cedricvanhool/",
        projects: [
          { name: "LLOSS" },
          { name: "Club Dérive" },
        ]
    }, 
    {
        name: "Jules Dockx",
        link: "https://www.instagram.com/docx.j/",
        projects: [
          { name: "Club Dérive" },
        ]
    }, 
    {
        name: "Fenia Proost",
        link: "https://www.instagram.com/feniaproost/",
        projects: [
          { name: "LLOSS" },
          { name: "Club Dérive" },
          { name: "Burenhinder" },
          { name: "Woodblocks" },
        ]
    }, 
    {
        name: " Cratje - Jonathan",
        link: "https://www.instagram.com/crat_j/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Laura Conant",
        link: "https://www.instagram.com/lau_c_n_n_t/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Jade Corbey",
        link: "https://www.instagram.com/jadecorbey/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Bjorne Baeten",
        link: "https://www.instagram.com/bjorne.baeten/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Marieke Jansenssen",
        link: "https://www.instagram.com/_mariekke/",
        projects: [
          { name: "Burenhinder" },
        ]
    }, 
    {
        name: "Nel Samoy",
        link: "https://www.instagram.com/nelsamwa/",
        projects: [
          { name: "Burenhinder" },
        ]
    }, 
    {
        name: "Marie Stella",
        link: "https://www.instagram.com/stellamarie.fyi/",
        projects: [
          { name: "Burenhinder" },
        ]
    }, 
    {
        name: "Isabel Brems",
        link: "https://www.instagram.com/isabelbrems/",
        projects: [
          { name: "Burenhinder" },
        ]
    }, 
    {
        name: "Soumaya Phéline",
        link: "https://www.instagram.com/soumayapheline/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Tilde Devylder",
        link: "https://www.instagram.com/tildedevylder/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Derege",
        link: "https://www.instagram.com/bb.reggie/",
        projects: [
          { name: "Montage" },
          { name: "Burenhinder" },
        ]
    }, 
    {
        name: "Tina De Keyser",
        link: "https://www.instagram.com/10adk/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Tine Pillaert",
        link: "Tine Pillaert",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Yousra",
        link: "https://www.instagram.com/youxsra/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Ana Spagnolo",
        link: "https://www.instagram.com/anitaspl/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Mina Lee Bultheel",
        link: "https://www.instagram.com/mina.lee.bultheel/",
        projects: [
          { name: "Montage" },
          { name: "Club Dérive" },
        ]
    }, 
    {
        name: "Tibbe Liesmons",
        link: "https://www.instagram.com/tehbbi_liesmonster/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Felix Mevis",
        link: "https://www.instagram.com/6692felix/",
        projects: [
          { name: "Montage" },
        ]
    }, 
    {
        name: "Julie Vanlook",
        link: "https://www.instagram.com/___j.ulievanlook/",
        projects: [
          { name: "Club Dérive Website" },
        ]
    }, 
    {
        name: "Minne Piot",
        link: "https://www.instagram.com/minnept/",
        projects: [
          { name: "Club Dérive Website" },
        ]
    }, 
    {
        name: "Joram De Cocker",
        link: "https://www.instagram.com/venster_bank/",
        projects: [
          { name: "Club Dérive Website" },
        ]
    }, 
    {
        name: "Ishmael Chaudry",
        link: "https://www.instagram.com/ishmael.chaudhry/",
        projects: [
          { name: "Club Dérive" },
          { name: "Genk Onstage" },
          { name: "Woodblocks" },
          { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
        name: "Rutger Van Mieghem",
        link: "https://www.instagram.com/giorgiooow/",
        projects: [
            { name: "LLOSS" },
        ]
    },
    {
        name: "Rainer Van Mieghem",
        link: "https://www.instagram.com/chipochipo__/",
        projects: [
          { name: "LLOSS" },
        ]
    }, 
    {
        name: "Bart Lauwers",
        link: "https://www.instagram.com/bartlwrs/",
        projects: [
            { name: "LLOSS" },
        ]
    }, 
    {
        name: "Sam Smet",
        link: "https://www.instagram.com/samsmet/",
        projects: [
            { name: "LLOSS" },
        ]
    }, 
    {
        name: "Kwinten Stoop",
        link: "https://www.instagram.com/djqtie/",
        projects: [
            { name: "LLOSS" },
        ]
    }, 
    {
        name: "Manu De Wulf",
        link: "https://www.instagram.com/o.simmie/",
        projects: [
            { name: "Kartier Maché" },
        ]
    }, 
    {
        name: "nezzeboo",
        link: "https://www.instagram.com/nezzeboo_/",
        projects: [
          { name: "Kartier Maché" },
        ]
    }, 
    {
        name: "Nadège Bibo-Tansia",
        link: "https://www.instagram.com/iristansia/",
        projects: [
            { name: "Kartier Maché" },
        ]
    }, 
    {
        name: "Yaqine Hamzaoui",
        link: "https://www.instagram.com/yaqine.raw/",
        projects: [
            { name: "Kartier Maché" },
        ]
    }, 
    {
        name: "Kiosk Radio",
        link: "https://www.instagram.com/kiosk.radio/",
        projects: [
          { name: "Woodblocks" },
        ]
    }, 
    {
        name: "Mickey",
        link: "https://www.instagram.com/mickeypictures/",
        projects: [
          { name: "Woodblocks" },
        ]
    },
    {
        name: "Berre Brans",
        link: "https://www.instagram.com/berre_brans/",
        projects: [
          { name: "Woodblocks" },
          { name: "Club Dérive" },
        ]
    }, 
    {
        name: "Seppe Claes",
        link: "https://www.instagram.com/claeseppe/",
        projects: [
          { name: "Microwave" },
        ]
    }, 
    {
        name: "Wolf Van Eenaeme",
        link: "https://www.instagram.com/wolfvaneenaeme/",
        projects: [
            { name: "Microwave" },
        ]
    }, 
    {
        name: "Stan Vrebos",
        link: "https://www.instagram.com/vrebosstan/",
        projects: [
            { name: "De Serre - Wildgroei" },
            { name: "Woodblocks" },
        ]
    }, 
    {
        name: "Juul Prinsen",
        link: "https://www.instagram.com/juul.prinsen/",
        projects: [
            { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
        name: "Lucas Dewereld",
        link: "https://www.instagram.com/lucas_dewereld/",
        projects: [
            { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
        name: "Mathias Van de Winkel",
        link: "https://www.instagram.com/vakwerk_projects/",
        projects: [
            { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
        name: "Max Gaublomme",
        link: "https://www.instagram.com/maito.mp3/",
        projects: [
            { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
        name: "Fleur Cornelis",
        link: "https://www.instagram.com/maito.mp3/",
        projects: [
            { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
        name: "Ruben Vanschoonbeek",
        link: "https://www.instagram.com/rbnvsb/",
        projects: [
            { name: "De Serre - Wildgroei" },
        ]
    }, 
    {
      name: "Arne Gentjens",
      link: "https://www.instagram.com/roestig_______/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Kobe Deckers",
      link: "https://www.instagram.com/louis_djaarvos/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Stefanie De Bakker",
      link: "https://www.instagram.com/stefaniedebakker/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Lotte Vrancken",
      link: "https://www.instagram.com/l.vrckn/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Pip Gyssels",
      link: "https://www.instagram.com/pip_sound/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Lucas Krzykos",
      link: "https://www.instagram.com/iamleukos/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Kirsten Vanlangenaeker",
      link: "https://www.instagram.com/Kirsten.vanlangenaeker/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Lene Berckmans",
      link: "https://www.instagram.com/chionisan/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Alexander Verlaak",
      link: "https://www.instagram.com/alexverlaak/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Arthur Loontjens",
      link: "https://www.instagram.com/arthurloontjens/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Minne Joris",
      link: "https://www.instagram.com/minnejoris/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Clara Golfa Sambay",
      link: "https://www.instagram.com/claragsb/",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }, 
     {
      name: "Flor Vannitsen",
      link: "https://www.instagram.com/florvannitsen",
      projects: [
          { name: "De Serre - Wildgroei" },
      ]
     }
]
