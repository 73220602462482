export const twoTowersData = [
    {
      src: require("../images/articles/TwoTowers/twotowers-opbouw-03.jpg"),
      publishedAt: "2023-06-23",
      author: "Lars Marcoen",
      slug: "transition-spaces",
      category: "article",
      order: "2023-06-23"
    },
    {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-04.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-02.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-01.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-17.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-29.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-16.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-opbouw-20.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-04.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-05.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-06.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-10.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-02.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-08.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-07.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-workshop-11.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-09.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-03.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-08.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-07.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-12.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-11.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-party-02.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-14.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-06.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-09.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-10.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-01.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-04.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-05.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-07.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      },
      {
        src: require("../images/articles/TwoTowers/twotowers-preworkshop-11.jpg"),
        publishedAt: "2023-06-23",
        author: "Lars Marcoen",
        slug: "transition-spaces",
        category: "article",
        order: "2023-06-23"
      }

]
