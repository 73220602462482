import React from 'react'

import img1 from "../assets/images/toolkitweek_4.jpeg"
import img2 from "../assets/images/toolkitweek_3.jpeg"

function Toolkitweek() {
  return (
    <div className='toolkitweek'>
      <div className='toolkitweek__scroll'>
        <img src={img1} alt="" />
        <img src={img2} alt="" />
      </div>
    </div>
  )
}

export default Toolkitweek