export const aboutData = [
    {
        title: "Druivelaar 1",
        srcFront: require("../images/agenda/CD_druivelaar.png"),
        srcBack: require("../images/agenda/CD_druivelaar_back.png"),
        eventDate: "2022-12-15",
        link: "",
        project: "",
        category: "agenda",
        order: "2022-12-15"
    },
    {
        eventName: "Take Two, Give One",
        srcFront: require("../images/agenda/druivelaar-montage-ws1.png"),
        srcBack: require("../images/agenda/druivelaar-montage-ws2.png"),
        eventDate: "2023-03-17",
        link: "https://pilar.brussels/en/events/in-situ-atelier-take-two-give-one",
        project: "Montage",
        category: "agenda",
        order: "2023-03-17"
    },
    {
        eventName: "MONTAGE w/ Klahrk, Golce, Stella K & more I PILAR ASAP",
        srcFront: require("../images/agenda/druivelaar-montage1.png"),
        srcBack: require("../images/agenda/druivelaar-montage2.png"),
        eventDate: "2023-03-18",
        link: "https://fb.me/e/3ewEO69O7",
        project: "Montage",
        category: "agenda",
        order: "2023-03-18"
    },
    {
        eventName: "BURENHINDER: POST HUMAN w Waynette + Distortina + DJ Jackhammer + Lolita + Burenhinder B4B",
        srcFront: require("../images/agenda/druivelaar-burenhinder1.png"),
        srcBack: require("../images/agenda/druivelaar-burenhinder2.png"),
        eventDate: "2023-03-25",
        link: "https://fb.me/e/2Y8Bxr4S0",
        project: "Burenhinder",
        category: "agenda",
        order: "2023-03-25"
    },
    {
        eventName: "LLOSS Open Air 2023",
        srcFront: require("../images/agenda/druivelaar-lloss1.png"),
        srcBack: require("../images/agenda/druivelaar-lloss2.png"),
        eventDate: "2023-06-23",
        link: "https://fb.me/e/1YfjC9SVY",
        project: "LLOSS",
        category: "agenda",
        order: "2023-06-23"
    },
    {
        eventName: "Genk Onstage 2023",
        srcFront: require("../images/agenda/druivelaar-GOS1.png"),
        srcBack: require("../images/agenda/druivelaar-GOS2.png"),
        eventDate: "2023-06-23",
        link: "https://www.genkonstage.be/",
        project: "Genk OnStage",
        category: "agenda",
        order: "2023-06-23"
    },
    {
        eventName: "Druivelaar 2",
        srcFront: require("../images/agenda/CD_druivelaar.png"),
        srcBack: require("../images/agenda/CD_druivelaar_back.png"),
        eventDate: "2023-07-02",
        link: "",
        project: "",
        category: "agenda",
        order: "2023-07-02"
    },
    {
        eventName: "Kartier Maché",
        srcFront: require("../images/agenda/druivelaar-kartiermache1.png"),
        srcBack: require("../images/agenda/druivelaar-kartiermache2.png"),
        eventDate: "2023-08-30",
        link: "https://www.instagram.com/p/CwKibyXtmfJ/",
        project: "",
        category: "agenda",
        order: "2023-08-30"
    },
    {
        eventName: "Woodblocks",
        srcFront: require("../images/agenda/druivelaar-woodblocks1.png"),
        srcBack: require("../images/agenda/druivelaar-woodblocks2.png"),
        eventDate: "2023-09-09",
        link: "https://fb.me/e/36D45lJFi",
        project: "",
        category: "agenda",
        order: "2023-09-09"
    },
    {
        eventName: "Microwave",
        srcFront: require("../images/agenda/druivelaar-microwave1.png"),
        srcBack: require("../images/agenda/druivelaar-microwave2.png"),
        eventDate: "2023-09-30",
        link: "https://fb.me/e/2Pl8AlKHu",
        project: "",
        category: "agenda",
        order: "2023-09-30"
    },
    {
        eventName: "Burenhinder - Aurora",
        srcFront: require("../images/agenda/druivelaar-aurora1.png"),
        srcBack: require("../images/agenda/druivelaar-aurora2.png"),
        eventDate: "2023-12-08",
        link: "https://fb.me/e/uLUMAZyyD",
        project: "",
        category: "agenda",
        order: "2023-12-08"
    },
]
