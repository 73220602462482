export const montageData = [
  {
    src: require("../images/articles/montage/DSC04879.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC05031.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC05079.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC03464.JPG"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/IMG_3705.JPG"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/IMG_3703.JPG"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/IMG_3701.JPG"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/IMG_3706.JPG"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/opencall-wit.png"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04884.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04989.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04956.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04884.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04959.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04887.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04894.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04999.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC04949.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  },
  {
    src: require("../images/articles/montage/DSC05029.jpg"),
    publishedAt: "2023-06-20",
    author: "Lars Marcoen & Bjorne Baeten & Jade Corbey",
    slug: "take-two-give-one",
    category: "article",
    order: "2023-03-17"
  }
]
