import React from 'react';

import video from "../assets/videos/background-dance-red.mp4";

import {agendaData} from '../assets/data/AGENDA.js'

import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, EffectCreative, EffectCards } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';



function Agendapage() {

  const today = new Date();
  const month = today.getMonth()+1;
  const year = today.getFullYear();
  const date = today. getDate();
  const currentDate = month + "/" + date + "/" + year;
  // console.log("today", currentDate);


  
  if(!agendaData) return <div className='loading'>Loading...</div>;

  return (
    <div className='agenda' id='scrollWindow'>
      
      <div className='agenda__backgroundVideo'>
        <video src={video} loop autoPlay muted playsInline></video>
      </div>

      <div className='agenda__container'>
        <div className='agenda__container__content'>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, EffectCreative, EffectCards ]}
            navigation
            keyboard={{
              enabled: true,
            }}
            mousewheel={true}
            grabCursor={true}
            effect={"cards"}
            cardsEffect={{
              perSlideOffset: 10,
              slideShadows: false,
              perSlideRotate: 5,
            }}
            // effect={"creative"}
            // creativeEffect={{
            //   prev: {
            //     shadow: false,
            //     translate: [0, 0, -800],
            //     rotate: [-180, 0, 0],
            //     opacity: 0
            //   },
            //   next: {
            //     shadow: false,
            //     translate: [0, 0, -800],
            //     rotate: [180, 0, 0],
            //   },
            // }}
            direction={"vertical"}       
            slidesPerView="1"
            initialSlide= "6"
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            >
              {
                agendaData  && agendaData.map((data, index) => (
                  <SwiperSlide key={index} id={index}>
                    <a href={data.link} target="_blank" rel="noreferrer">
                      <img className='article__slider__image' id={`agenda${index}`} src={data.srcFront}  alt=""/>
                      <img className='article__slider__image' id={`agenda${index}`} src={data.srcBack}  alt=""/>
                    </a>
                  </SwiperSlide>
                ))
              }
          </Swiper>

        </div>
      </div>

    </div>
  )
}

export default Agendapage