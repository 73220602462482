import React, {useState} from 'react'

import { Slider } from '@mui/material';

let visOpencall = 0;
let visRenders = 0;
let visImage = 0;
let visAgenda = 0;
let visArticle = 0;

let opencallDiv = document.getElementsByClassName('archive__content__opencall');
let articleDiv = document.getElementsByClassName('archive__content__article');
let rendersDiv = document.getElementsByClassName('archive__content__renders');
let agendaDiv = document.getElementsByClassName('archive__content__agenda');
let imagesDiv = document.getElementsByClassName('archive__content__slider');


function filterOpencall(){


  visOpencall += 1
  let opencallButton = document.getElementById('buttonOpencall');


  if(visOpencall === 1){
    Array.from(opencallDiv).forEach((div) => {
      div.style.display = "none"
    })
    opencallButton.style.color = "lightgray"
    opencallButton.style.backgroundColor = "white"
    opencallButton.style.borderColor = "lightgray"

    visOpencall = -1;

  }else{
    Array.from(opencallDiv).forEach((div) => {
      div.style.display = "block"
    })
    opencallButton.style.color = "white"
    opencallButton.style.backgroundColor = "lightgray"
    opencallButton.style.borderColor = "lightgray"
  } 
}
function filterArticle(){
  visArticle += 1
  let articleButton = document.getElementById('buttonArticle');


  if(visArticle === 1){
    Array.from(articleDiv).forEach((div) => {
      div.style.display = "none"
    })
    articleButton.style.color = "lightgray"
    articleButton.style.backgroundColor = "white"
    articleButton.style.borderColor = "lightgray"

    visArticle = -1

  }else{
    Array.from(articleDiv).forEach((div) => {
      div.style.display = "block"
    })
    articleButton.style.color = "white"
    articleButton.style.backgroundColor = "lightgray"
    articleButton.style.borderColor = "lightgray"
  }
  
}
function filterAgenda(){
  visAgenda += 1
  let agendaButton = document.getElementById('buttonAgenda');


  if(visAgenda === 1){
    Array.from(agendaDiv).forEach((div) => {
      div.style.display = "none"
    })
    agendaButton.style.color = "lightgray"
    agendaButton.style.backgroundColor = "white"
    agendaButton.style.borderColor = "lightgray"

    visAgenda = -1

  }else{
    Array.from(agendaDiv).forEach((div) => {
      div.style.display = "block"
    })
    agendaButton.style.color = "white"
    agendaButton.style.backgroundColor = "lightgray"
    agendaButton.style.borderColor = "lightgray"

  }
  
}
function filterImages(){
  visImage += 1
  let imagesButton = document.getElementById('buttonImages');


  if(visImage === 1){
    Array.from(imagesDiv).forEach((div) => {
      div.style.display = "none"
    })
    imagesButton.style.color = "lightgray"
    imagesButton.style.backgroundColor = "white"
    imagesButton.style.borderColor = "lightgray"

    visImage = -1

  }else{
    Array.from(imagesDiv).forEach((div) => {
      div.style.display = "block"
    })
    imagesButton.style.color = "white"
    imagesButton.style.backgroundColor = "lightgray"
    imagesButton.style.borderColor = "lightgray"
  }
  
}
function filterRenders(){
  visRenders += 1
  let rendersButton = document.getElementById('buttonRenders');


  if(visRenders === 1){
    Array.from(rendersDiv).forEach((div) => {
      div.style.display = "none"
    })
    rendersButton.style.color = "lightgray"
    rendersButton.style.backgroundColor = "white"
    rendersButton.style.borderColor = "lightgray"

    visRenders = -1

  }else{
    Array.from(rendersDiv).forEach((div) => {
      div.style.display = "block"
    })
    rendersButton.style.color = "white"
    rendersButton.style.backgroundColor = "lightgray"
    rendersButton.style.borderColor = "lightgray"
  }
  
}

function divSizeChange(){
  let slider = document.getElementById('slider').value
  console.log("SLIDER", slider);

  const divSize = document.getElementsByClassName('archive__content');
  Array.from(divSize).forEach((div) => div.style.height = slider + 5 + "px")
}

function Filter() {

  const [value, setValue] = useState(30);

  const sliderChange = (event, newValue) => {
    setValue(newValue);
  };



  

  return (
    <div className="filter__wrapper" id="filter__wrapper">
      <h1>Filter:</h1>
      {/* <button onClick={filterAll}>All</button> */}
      {/* <button onClick={filterOpencall} id="buttonOpencall">Opencall</button> */}
      <button onClick={filterArticle} id="buttonArticle">Article</button>
      <button onClick={filterAgenda} id="buttonAgenda">Agenda</button>
      <button onClick={filterImages} id="buttonImages">Images</button>
      {/* <button onClick={filterRenders} id="buttonRenders">Renders</button> */}
      <Slider onClick={divSizeChange}
        size="small"
        defaultValue={70}
        aria-label="Small"
        valueLabelDisplay="auto"
        value={value}
        onChange={sliderChange}
      />
       <input type="hidden" id="slider" value={value}/>
    </div>
  )
}

export default Filter