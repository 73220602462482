import React from 'react'

import video from '../assets/videos/background-dance-red.mp4'
import poster from '../assets/images/posterComingSoon.png'

function Comingsoonpage() {
  return (
    <div className='Comingsoonpage'>
        <video src={video} poster={poster} width="100%" height="auto" loop autoPlay muted playsInline></video>
        <div className='comingSoonText'>
          Club Dérive website <br /> Coming Soon!
        </div>
    </div>
  )
}

export default Comingsoonpage