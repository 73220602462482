export const postHumanData = [
    {
        src: require("../images/articles/PostHuman/bib.png"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_0.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_1.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_15.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_2.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_22.jpg"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_3.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_4.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_5.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_6.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_7.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_8.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_10.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_11.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_12.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_13.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_14.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_15.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_16.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_17.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_18.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_19.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_20.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-opbouw_21.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_1.jpg"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_2.jpg"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_3.jpg"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_4.jpg"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_5.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_6.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_7.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_8.jpg"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_11.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_9.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_10.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
    {
        src: require("../images/articles/PostHuman/posthuman-party_13.JPG"),
        publishedAt: "2023-05-29",
        author: "Lars Marcoen",
        slug: "post-human",
        category: "article",
        order: "2023-03-25"
    },
]
