import React from 'react';

import video1 from '../../assets/videos/articles/woodblocks/C0009_1.mp4';
import video2 from '../../assets/videos/articles/woodblocks/woodblocks-opbouw-vid_1.mp4';
import video3 from '../../assets/videos/articles/woodblocks/woodblocks-opbouw-vid_2.mp4';
import video4 from '../../assets/videos/articles/woodblocks/C0010_1.mp4';
import video5 from '../../assets/videos/articles/woodblocks/C0013_1.mp4';

import img1 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-02.jpg';
import img2 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-04.jpg';
import img3 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-03.jpg';
import img4 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-01.jpg';
import img5 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-05.jpg';
import img6 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-09.jpg';
import img7 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-07.jpg';
import img8 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-08.jpg';
import img9 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-11.jpg';
import img10 from '../../assets/images/articles/woodblocks/woodblocks-opbouw-12.jpg';
import img11 from '../../assets/images/articles/woodblocks/woodblocks-party-36.jpg';
import img12 from '../../assets/images/articles/woodblocks/woodblocks-party-05.jpg';
import img13 from '../../assets/images/articles/woodblocks/woodblocks-party-17.jpg';
import img14 from '../../assets/images/articles/woodblocks/woodblocks-party-20.jpg';
import img15 from '../../assets/images/articles/woodblocks/woodblocks-party-01.jpg';
import img16 from '../../assets/images/articles/woodblocks/woodblocks-party-14.jpg';
import img17 from '../../assets/images/articles/woodblocks/woodblocks-party-19.jpg';
import img18 from '../../assets/images/articles/woodblocks/woodblocks-party-35.jpg';
import img19 from '../../assets/images/articles/woodblocks/woodblocks-party-21.jpg';
import img20 from '../../assets/images/articles/woodblocks/woodblocks-party-28.jpg';
import img21 from '../../assets/images/articles/woodblocks/woodblocks-party-29.jpg';
import img22 from '../../assets/images/articles/woodblocks/woodblocks-party-38.jpg';
import img23 from '../../assets/images/articles/woodblocks/woodblocks-party-03.jpg';
import img24 from '../../assets/images/articles/woodblocks/woodblocks-party-09.jpg';
import img25 from '../../assets/images/articles/woodblocks/woodblocks-party-06.jpg';
import img26 from '../../assets/images/articles/woodblocks/woodblocks-party-07.jpg';
import img27 from '../../assets/images/articles/woodblocks/woodblocks-party-22.jpg';
import img28 from '../../assets/images/articles/woodblocks/woodblocks-party-42.jpg';
import img29 from '../../assets/images/articles/woodblocks/woodblocks-party-48.jpg';
import img30 from '../../assets/images/articles/woodblocks/woodblocks-party-46.jpg';
import img31 from '../../assets/images/articles/woodblocks/woodblocks-party-25.jpg';
import img32 from '../../assets/images/articles/woodblocks/woodblocks-party-41.jpg';
import img33 from '../../assets/images/articles/woodblocks/woodblocks-party-45.jpg';
import img34 from '../../assets/images/articles/woodblocks/woodblocks-party-44.jpg';
import img35 from '../../assets/images/articles/woodblocks/woodblocks-party-43.jpg';
// import img36 from '../../assets/images/articles/woodblocks/woodblocks-party-39.jpg';


function Woodblocks() {
  return (
    <div className='woodblocks'>
        <div className='woodblocks__backgroundVideo'>
            <div className='woodblocks__backgroundVideo__wrapper'>
                <div className='woodblocks__backgroundVideo__title'>
                    <h1 className='editH1'>Project: Woodblocks - Kiosk Radio</h1>
                    <h2 className='editH2'>9-10.09.2023</h2>
                </div>
            </div>
            <video src={video1} width="100%" height="auto" loop autoPlay muted playsInline></video>
        </div>

        <div className='woodblocks__container'>
            <div className='woodblocks__container__title'>
                <h1 className='editH1'>Project: <br /> Woodblocks - Kiosk Radio</h1>
                <h2 className='editH2'>9-10.09.2023</h2>
            </div>

            <div className='woodblocks__container__images woodblocks__container__images1'>
                <img className='woodblocks__img1' src={img1} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images2 space__between'>
                <img className='woodblocks__img2' src={img2} alt="" />
                <img className='woodblocks__img3' src={img3} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images4'>
                <img className='woodblocks__img4' src={img4} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images5 space__between'>
                <img className='woodblocks__img5' src={img5} alt="" />
                <img className='woodblocks__img6' src={img6} alt="" />
            </div>

            <div className='woodblocks__container__images'>
                <video src={video2} width="65%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className='woodblocks__container__images woodblocks__container__images7'>
                <img className='woodblocks__img7' src={img7} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images8'>
                <img className='woodblocks__img8' src={img8} alt="" />
            </div>

            <div className='woodblocks__container__images'>
                <video src={video3} width="100%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className='woodblocks__container__images woodblocks__container__images9 space__between'>
                <img className='woodblocks__img9' src={img9} alt="" />
                <img className='woodblocks__img10' src={img10} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images11'>
                <img className='woodblocks__img11' src={img11} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images12 space__between'>
                <img className='woodblocks__img12' src={img12} alt="" />
                <img className='woodblocks__img13' src={img13} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images14 space__between flex__end'>
                <img className='woodblocks__img14' src={img14} alt="" />
                <img className='woodblocks__img15' src={img15} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images16 space__between'>
                <img className='woodblocks__img16' src={img16} alt="" />
                <img className='woodblocks__img17' src={img17} alt="" />
            </div>

            <div className='woodblocks__container__images'>
                <video src={video4} width="100%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className='woodblocks__container__images woodblocks__container__images18'>
                <img className='woodblocks__img18' src={img18} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images19'>
                <img className='woodblocks__img19' src={img19} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images20 space__between'>
                <img className='woodblocks__img20' src={img20} alt="" />
                <img className='woodblocks__img21' src={img21} alt="" />
            </div>
            
            <div className='woodblocks__container__images woodblocks__container__images22'>
                <img className='woodblocks__img22' src={img22} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images23'>
                <img className='woodblocks__img23' src={img23} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images24'>
                <img className='woodblocks__img24' src={img24} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images25 space__between'>
                <img className='woodblocks__img25' src={img25} alt="" />
                <img className='woodblocks__img26' src={img26} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images27'>
                <img className='woodblocks__img27' src={img27} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images28 space__between'>
                <img className='woodblocks__img28' src={img28} alt="" />
                <img className='woodblocks__img29' src={img29} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images30 space__between flex__end'>
                <img className='woodblocks__img30' src={img30} alt="" />
                <img className='woodblocks__img31' src={img31} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images32'>
                <img className='woodblocks__img32' src={img32} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images33'>
                <img className='woodblocks__img33' src={img33} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images34'>
                <img className='woodblocks__img34' src={img34} alt="" />
            </div>

            <div className='woodblocks__container__images woodblocks__container__images35'>
                <img className='woodblocks__img35' src={img35} alt="" />
            </div>

            <div className='woodblocks__container__images'>
                <video src={video5} width="100%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className="montage__container__body">
                <div className='montage__body__text__middle middle__center' id='get__H2'>
                    <i>All Pictures and Videos are taken by <br /> Simon Breynaert</i>
                </div>
            </div>

        </div>


    </div>
  )
}

export default Woodblocks