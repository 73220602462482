import React from 'react'
// import {client} from "../client.js"

import poster1 from '../assets/images/homepage-bg.jpg'
import poster2 from '../assets/images/homepage-bg-mobile.jpg'

import video1 from '../assets/videos/slower-bg_1.mp4'
import video2 from '../assets/videos/homepage-bg-mobile.mp4'
// import FlexHeader from '../components/FlexHeader.jsx'


function hoverInfo(){
  document.getElementById('hoverInfo').style.display = 'block'
}
function goneInfo(){
  document.getElementById('hoverInfo').style.display = 'none'
}

function Homepage() {


  if(!video1) return <div className='loading'>Loading...</div>;
  
  return (
    <div className='home' id='background'>
      {/* <FlexHeader/> */}
      {/* <img src="" alt="" id='backgroundImage' className='backgroundImage'/> */}
      <div className='home__wrapper__video home__desktop'>
        <video className='home__video' src={video1} poster1={poster1} width="100%" height="auto" loop autoPlay muted playsInline></video>
      </div>
      <div className='home__wrapper__video home__mobile'>
        <video className='home__video' src={video2} poster={poster2} width="100%" height="auto" loop autoPlay muted playsInline></video>
      </div>
     
      <div className='home__website__info'>
        <div className='infoButton' onMouseEnter={hoverInfo} onMouseLeave={goneInfo}>?</div>

        <div className='website__info' id='hoverInfo'>
          <h1>Website Info</h1>
          {/* <p className='firstP'>This website is design throught a workshop organised by Cosima.... We try to be as opensource as we can be...</p> */}
          <div>
            <h2>Designed by</h2>
            <p>Cosima Rosie Lagae,</p>
            <p>Joram De Cocker,</p>
            <p>Julie Vanlook,</p>
            <p>Lars Marcoen,</p>
            <p>Minne Piot</p>
          </div>
          <div>
            <h2>Developed by</h2>
            <p>Cosima Rosie Lagae</p>
          </div>
          <div>
            <h2>Fonts used</h2>
            <p><strong>Adelphe</strong> - Eugénie Bidaut,</p>
            <p><strong>Acumin-Variable</strong> - Robert Slimbach,</p>
            <p><strong>EB Garamond</strong> - Georg Duffner + Octavio Pardo</p>
            <p><strong>Fredoka</strong> - Milena Brandão + Hafontia,</p>
            <p><strong>Testue</strong> - Bavo Hendrickx</p>
            <p><strong>Tribalium Neue</strong> - Fonderie.download,</p>
            <p><strong>Vg5000</strong> - Justin Bihan,</p>
            <p><strong>Wondertype</strong> - Clémence Fontaine,</p>
            <p><strong>0xa000</strong> - Øyvind Kolås,</p>
            <p><strong>Ark-es Dense/Solid</strong> - Eddie Stuart,</p>
            <p><strong>Degheest FT88 Gothic</strong> - Ange Degheest + Oriane Charvieux + Mandy Elbé,</p>
            <p><strong>Heavy</strong> - Alexander Le Sage de Fontenay,</p>
            <p><strong>Messapia</strong> - Luca Marsano,</p>
            <p><strong>Punk Nova</strong> - Donald Knuth + Khaled Hosny,</p>
            <p><strong>Faster One</strong> - Eduardo Tunni,</p>
            <p><strong>Macondo Swash Caps</strong> - John Vargas Beltrán,</p>
            <p><strong>Potta One</strong> - Font Zone 108,</p>
            <p><strong>press Start 2P</strong> - CodeMan38,</p>
            <p><strong>Rubik Beastly/Puddles/Glitch/Spray Paint/Burned/Bubbles</strong> - Luke Prowse,</p>
            <p><strong>Titan One</strong> - Rodrigo Fuenzalida,</p>
            <p><strong>UnifrakturMaguntia</strong> - j. 'mach' wust</p>
            <p><strong>Space Mono</strong> - Colophon</p>
          </div>
        </div>
      </div>

      <div className='mobile home__mobile'>
        
      </div>

    </div>
  )
}

export default Homepage