export const woodblocksData = [
    {
      src: require("../images/articles/woodblocks/woodblocks-opbouw-01.jpg"),
      publishedAt: "2023-09-09",
      author: "Cosima Rosie Lagae",
      slug: "woodblocks",
      category: "article",
      order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-02.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-03.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-04.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-05.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
      },
      {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-07.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
      },
      {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-08.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
      },
      {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-09.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
      },
      {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-11.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
      },
      {
        src: require("../images/articles/woodblocks/woodblocks-opbouw-12.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-01.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-03.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-05.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },      
    {
        src: require("../images/articles/woodblocks/woodblocks-party-06.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },     
    {
        src: require("../images/articles/woodblocks/woodblocks-party-07.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },      
    {
        src: require("../images/articles/woodblocks/woodblocks-party-09.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },     
    {
        src: require("../images/articles/woodblocks/woodblocks-party-14.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },      
    {
        src: require("../images/articles/woodblocks/woodblocks-party-17.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },      
    {
        src: require("../images/articles/woodblocks/woodblocks-party-19.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },      
    {
        src: require("../images/articles/woodblocks/woodblocks-party-20.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },      
    {
        src: require("../images/articles/woodblocks/woodblocks-party-21.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-22.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-25.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-28.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-29.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-35.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-36.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-38.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-39.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-41.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-42.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-43.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-44.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-45.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-46.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    },
    {
        src: require("../images/articles/woodblocks/woodblocks-party-48.jpg"),
        publishedAt: "2023-09-09",
        author: "Cosima Rosie Lagae",
        slug: "woodblocks",
        category: "article",
        order: "2023-09-09"
    }
]
