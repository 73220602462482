import React from 'react';

import video1 from '../../assets/videos/articles/TwoTowers/C0013.mp4';
import video2 from '../../assets/videos/articles/TwoTowers/twotowers-opbouw-gif_1.mp4';
import video3 from '../../assets/videos/articles/TwoTowers/twotowers-opbouw-gif_2.mp4';
import video4 from '../../assets/videos/articles/TwoTowers/C0020.MP4';
import video5 from '../../assets/videos/articles/TwoTowers/C0015.mp4';

import img1 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-03.jpg';
import img2 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-04.jpg';
import img3 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-02.jpg';
import img4 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-01.jpg';
import img5 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-17.jpg';
import img6 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-29.jpg';
import img7 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-16.jpg';
import img8 from '../../assets/images/articles/TwoTowers/twotowers-opbouw-20.jpg';
import img9 from '../../assets/images/articles/TwoTowers/twotowers-workshop-04.jpg';
import img10 from '../../assets/images/articles/TwoTowers/twotowers-workshop-05.jpg';
import img11 from '../../assets/images/articles/TwoTowers/twotowers-workshop-06.jpg';
import img12 from '../../assets/images/articles/TwoTowers/twotowers-workshop-10.jpg';
import img13 from '../../assets/images/articles/TwoTowers/twotowers-workshop-02.jpg';
import img14 from '../../assets/images/articles/TwoTowers/twotowers-workshop-08.jpg';
import img15 from '../../assets/images/articles/TwoTowers/twotowers-workshop-07.jpg';
import img16 from '../../assets/images/articles/TwoTowers/twotowers-workshop-11.jpg';
import img17 from '../../assets/images/articles/TwoTowers/twotowers-party-09.jpg';
import img18 from '../../assets/images/articles/TwoTowers/twotowers-party-03.jpg';
import img19 from '../../assets/images/articles/TwoTowers/twotowers-party-08.jpg';
import img20 from '../../assets/images/articles/TwoTowers/twotowers-party-07.jpg';
import img21 from '../../assets/images/articles/TwoTowers/twotowers-party-12.jpg';
import img22 from '../../assets/images/articles/TwoTowers/twotowers-party-11.jpg';
import img23 from '../../assets/images/articles/TwoTowers/twotowers-party-02.jpg';
import img24 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-14.jpg';
import img25 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-06.jpg';
import img26 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-09.jpg';
import img27 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-10.jpg';
import img28 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-01.jpg';
import img29 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-04.jpg';
import img30 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-05.jpg';
import img31 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-07.jpg';
import img32 from '../../assets/images/articles/TwoTowers/twotowers-preworkshop-11.jpg';



function TwoTowers() {
  return (
    <div className='twoTowers'>
        <div className='twoTowers__backgroundVideo'>
            <div className='twoTowers__backgroundVideo__wrapper'>
                <div className='twoTowers__backgroundVideo__title'>
                    <h1 className='editH1'>Project: Two Towers - LLOSS</h1>
                    <h2 className='editH2'>23-24.06.2023</h2>
                </div>
            </div>
            <video src={video1} width="100%" height="auto" loop autoPlay muted playsInline></video>
        </div>

        <div className='twoTowers__container'>
            <div className='twoTowers__container__title'>
                <h1 className='editH1'>Project: <br /> Two Towers - LLOSS</h1>
                <h2 className='editH2'>23-24.06.2023</h2>
            </div>

            <div className='twoTowers__container__images twoTowers__container__images1 space__between flex__end'>
                <img className='twoTowers__img1' src={img1} alt="" />
                <img className='twoTowers__img2' src={img2} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images2 space__between flex__end'>
                <img className='twoTowers__img3' src={img3} alt="" />
                <img className='twoTowers__img4' src={img4} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images3'>
                <video src={video2} width="100%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className='twoTowers__container__images twoTowers__container__images4'>
                <img className='twoTowers__img5' src={img5} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images5'>
                <video src={video3} width="100%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className='twoTowers__container__images twoTowers__container__images6'>
                <img className='twoTowers__img6' src={img6} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images7'>
                <img className='twoTowers__img7' src={img7} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images8'>
                <img className='twoTowers__img8' src={img8} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images9 space__between'>
                <img className='twoTowers__img9' src={img9} alt="" />
                <img className='twoTowers__img10' src={img10} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images11'>
                <img className='twoTowers__img11' src={img11} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images12'>
                <img className='twoTowers__img12' src={img12} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images13'>
                <img className='twoTowers__img13' src={img13} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images14 space__between'>
                <img className='twoTowers__img14' src={img14} alt="" />
                <img className='twoTowers__img15' src={img15} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images16'>
                <img className='twoTowers__img16' src={img16} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images17'>
                <img className='twoTowers__img17' src={img17} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images18 space__between'>
                <img className='twoTowers__img18' src={img18} alt="" />
                <video className='twoTowers__video3' src={video4} width="100%" height="auto" loop autoPlay muted playsInline></video>
            </div>

            <div className='twoTowers__container__images twoTowers__container__image19'>
                <img className='twoTowers__img19' src={img19} alt="" />
            </div>
            
            <div className='twoTowers__container__images twoTowers__container__image20'>
                <img className='twoTowers__img20' src={img20} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__image21'>
                <img className='twoTowers__img21' src={img21} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__image22'>
                <img className='twoTowers__img22' src={img22} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images23 space__between'>
                <img className='twoTowers__img23' src={img23} alt="" />
                <video className='twoTowers__video4' src={video5} width="100%" height="auto" loop autoPlay muted playsInline ></video>
            </div>
            
            <div className='twoTowers__container__images twoTowers__container__images24 space__between flex__end'>
                <img className='twoTowers__img24' src={img24} alt="" />
                <img className='twoTowers__img25' src={img25} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images26'>
                <img className='twoTowers__img26' src={img26} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images24 space__between'>
                <img className='twoTowers__img24' src={img27} alt="" />
                <img className='twoTowers__img25' src={img28} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images29'>
                <img className='twoTowers__img29' src={img29} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images30 space__between'>
                <img className='twoTowers__img30' src={img30} alt="" />
                <img className='twoTowers__img31' src={img31} alt="" />
            </div>

            <div className='twoTowers__container__images twoTowers__container__images32'>
                <img className='twoTowers__img32' src={img32} alt="" />
            </div>

            <div className="montage__container__body">
                <div className='montage__body__text__middle middle__center' id='get__H2'>
                    <i>All Pictures and Videos are taken by <br /> Simon Breynaert</i>
                </div>
            </div>

        </div>
    </div>
  )
}

export default TwoTowers