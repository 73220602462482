import React from 'react';

import bg from '../assets/images/IMG_20221113_132610621_HDR.jpg'

import { contributersData } from '../assets/data/CONTRIBUTERS.js';



function Contributerspage() {
  
  var fonts = ["tribalium", "vg5000", "wondertype", "0xa000", "arkes dense", "arkes solid", "ft88g", "heavy", "messapia", "punknova", "Faster One", "Fredoka", "Macondo Swash Caps", "Potta One", "Press Start 2P", "Rubik Beastly", "Rubik Puddles", "Rubik Glitch", "Rubik Spray Paint", "Rubik Burned", "Rubik Bubbles", "Titan One", "UnifrakturMaguntia", "testue"];
  var rand = Math.floor(Math.random() * fonts.length);
  
  var changingFont = fonts[rand];

  // console.log(allContributers[0].projects)

  if(!contributersData) return <div className='loading'>Loading...</div>;

  return (
    <div className='contributers'>

      <img className='bg' src={bg} alt="" />
      <div className='contributer__wrapper'>
        <ul className='contributer__wrapper__fonts'>
          {
            contributersData && contributersData.map((contributer, index) => (
                <li className={`contributer__wrapper__cards contributer__wrapper__cards${index}`}>
                  <a className='contributer__wrapper__name editH2' href={contributer.link} target="_blank" rel="noreferrer" style={{ fontFamily: `${changingFont}`}}>{contributer.name},</a>
                  <div className={`contributer__wrapper__projects`}>
                    <h1>Projects</h1>
                  {
                    contributer.projects && contributer.projects.map((p) => (
                      <h2 className='editH2'>{p.name},</h2>
                    ))
                  }
                  </div>
                </li>
             
            ))
          }
          <li style={{ fontFamily: `${changingFont}` }}>...</li>
        </ul>
      </div>

    </div>
  )
}

export default Contributerspage